import { Form, Formik } from 'formik'
import * as yup from 'yup'
import 'iframe-resizer/js/iframeResizer.contentWindow'
import { postCreateLead, saveFileToRx } from '../../api/invoices'
import LeadSubmitted from './LeadSubmitted'
import {
  NumberInputField,
  TextareaField,
  TextInputField
} from '@toasttab/buffet-pui-forms'
import {
  AutorenewIcon,
  EmailIcon,
  GuestIcon,
  PhoneIcon,
  ScheduleIcon
} from '@toasttab/buffet-pui-icons'
import { DatePicker } from '@toasttab/buffet-pui-date-picker'
import cx from 'classnames'
import { Label } from '@toasttab/buffet-pui-dropdowns'
import { Select } from '@toasttab/buffet-pui-select'
import BeoForm, { areAllRequiredFieldsPopulated } from './BeoForm'
import { FileResponse, LeadFormValue } from '../../types/LeadTypes'
import FileInput from '../FileInput/FileInput'
import { Button } from '@toasttab/buffet-pui-buttons'
import React, { useCallback, useState } from 'react'
import { setHours, setMinutes, addDays } from 'date-fns'
import { useQueryParams } from '../../utils/utils'
import { PublicEventTypeResponse } from '../../types/EventTypes'
import { zonedTimeToUtc } from 'date-fns-tz'
import './styles.css'

export const getLeadTitle = (eventType: PublicEventTypeResponse | null) => {
  return eventType?.leadTitle
    ? eventType.leadTitle
    : eventType?.restaurantBranding?.removeCateringName &&
      eventType?.name.trim() === 'Catering'
    ? 'Inquiry'
    : `${eventType?.name} Inquiry`
}

const buildTimeSelect = () => {
  const result = []
  let hours, minutes, ampm
  for (let i = 0; i < 1440; i += 15) {
    hours = Math.floor(i / 60)
    minutes = i % 60
    if (minutes < 10) {
      minutes = '0' + minutes // adding leading zero
    }
    ampm = hours % 24 < 12 ? 'AM' : 'PM'
    hours = hours % 12
    if (hours === 0) {
      hours = 12
    }
    result.push({
      label: hours + ':' + minutes + ' ' + ampm,
      value: i
    })
  }
  return result
}

type ValueType = {
  name: string
  phone: string
  email: string
  date: Date | undefined
  time: number | undefined
  endTime: number | undefined
  notes: string
  formValues: LeadFormValue[]
  attachments: FileResponse[]
}

const initialValues: ValueType = {
  name: '',
  phone: '',
  email: '',
  date: undefined,
  time: undefined,
  endTime: undefined,
  notes: '',
  formValues: [],
  attachments: []
}

function setMinutesForDate(
  date: Date | undefined,
  minutes: number | undefined,
  rxTimeZoneId: string | undefined
) {
  if (!date || minutes === undefined || !rxTimeZoneId) return null

  const hours = Math.floor(minutes / 60)
  const mins = minutes % 60
  const dateWithTime = setMinutes(setHours(date, hours), mins)

  // If the user accesses the lead form in a different timezone than the Rx's timezone,
  // dateWithTime will include the user's timezone. So need to swap in
  // the Rx timezone instead before converting to UTC
  return zonedTimeToUtc(dateWithTime, rxTimeZoneId)
}

function setOptionalMinutesForDate(
  date: Date | undefined,
  minutes: number | undefined,
  rxTimeZoneId: string | undefined
) {
  if (!date || !rxTimeZoneId) return null

  if (minutes) {
    return setMinutesForDate(date, minutes, rxTimeZoneId)
  } else {
    // If the user accesses the lead form in a different timezone than the Rx's timezone,
    // date will include the user's timezone. So need to swap in the Rx timezone instead
    // before converting to UTC
    return zonedTimeToUtc(date, rxTimeZoneId)
  }
}

export const LeadForm = ({
  eventType
}: {
  eventType: PublicEventTypeResponse | null
}) => {
  const params = useQueryParams()

  const isButtonDisabled = useCallback(
    (
      isSubmitting: boolean | undefined,
      values: any,
      eventType: any,
      errors: any
    ): boolean => {
      return (
        isSubmitting ||
        !values?.name ||
        !values?.phone ||
        !values?.email ||
        (eventType?.requireTime && !values?.date) ||
        (eventType?.requireTime && !values?.time) ||
        !areAllRequiredFieldsPopulated(
          eventType ? eventType!.formFields : [],
          values.formValues,
          eventType?.showNonInternalFields
        ) ||
        Object.keys(errors).length > 0
      )
    },
    []
  )

  const leadTitle = getLeadTitle(eventType)

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [submitted, setSubmitted] = useState(false)

  const rxTimeZoneId = eventType?.restaurantTimeZoneId

  const imageFieldName = eventType?.imageFieldName || 'Upload Image'

  return (
    <div>
      <div
        className={cx(
          'pb-3 mb-4 font-semibold text-dark-gray border-bg-darken-8 md:pb-4 brandedHeaderText',
          params.isSitesView === 'true'
            ? 'type-headline-3'
            : 'border-b type-large'
        )}
      >
        {!submitted ? leadTitle : 'Inquiry Submitted'}
      </div>
      <div className='brandedBodyText'>
        <Formik
          validationSchema={yup.object().shape({
            name: yup.string().required('Name is required'),
            phone: yup
              .string()
              .required('Please enter a valid phone number')
              .matches(/^[0-9]+$/, 'Please enter valid phone number')
              .min(10, 'Please enter valid phone number')
              .max(10, 'Please enter valid phone number'),
            email: yup
              .string()
              .email('Please enter a valid email')
              .required('Email is required'),
            time: yup.number().test({
              message: 'Start time is required',
              test(value, ctx) {
                const endTime = ctx.parent.endTime
                if (endTime === undefined || endTime === null) return true
                return value !== undefined && value !== null
              }
            }),
            endTime: yup.number().test({
              message: 'End time must be greater than start time',
              test(value, ctx) {
                const startTime = ctx.parent.time
                if (value === undefined || value === null) return true
                return value >= startTime
              }
            })
          })}
          initialValues={initialValues}
          onSubmit={async (values) => {
            const {
              date,
              name,
              email,
              phone,
              notes,
              time,
              endTime,
              formValues,
              attachments
            } = values
            setIsSubmitting(true)
            const [firstName, ...lastName] = name.split(' ').filter(Boolean)
            await postCreateLead({
              restaurantGuid: params?.rx,
              eventTypeGuid: params?.ot,
              firstName: firstName,
              lastName: lastName.join(' '),
              email: email,
              phoneNumber: phone,
              notes: notes,
              date: setOptionalMinutesForDate(
                date,
                time,
                rxTimeZoneId
              )?.toISOString(),
              time: setMinutesForDate(date, time, rxTimeZoneId)?.toISOString(),
              endTime: setMinutesForDate(
                date,
                endTime,
                rxTimeZoneId
              )?.toISOString(),
              formValues: formValues,
              attachments: attachments
            })
            setIsSubmitting(false)
            setSubmitted(true)
          }}
        >
          {({ setFieldValue, values, resetForm, errors, touched }) => {
            if (submitted) {
              return (
                <LeadSubmitted
                  onBack={() => {
                    resetForm()
                    setSubmitted(false)
                  }}
                  managementSetGuid={eventType?.managementSetGuid}
                />
              )
            } else {
              return (
                <Form className={'flex flex-col'}>
                  <div className={'flex flex-col md:flex-row w-full mb-4'}>
                    <TextInputField
                      containerClassName={'w-full mr-3'}
                      name={'name'}
                      prefix={<GuestIcon accessibility='decorative' />}
                      prefixVariant={'icon'}
                      label='Name'
                      errorText={errors.name}
                      required
                    />
                    <NumberInputField
                      containerClassName={'w-full'}
                      label={'Phone'}
                      prefix={<PhoneIcon accessibility='decorative' />}
                      prefixVariant={'icon'}
                      format='(###) ###-####'
                      allowEmptyFormatting
                      mask='_'
                      name='phone'
                      errorText={errors.phone}
                      required
                    />
                  </div>
                  <TextInputField
                    name={'email'}
                    label='Email'
                    required
                    prefix={<EmailIcon accessibility='decorative' />}
                    prefixVariant={'icon'}
                    containerClassName={'mb-4'}
                    errorText={errors.email}
                  />
                  <div className='grid grid-cols-1 md:grid-cols-2 w-full mb-4 gap-4'>
                    <DatePicker
                      label='Date'
                      testId='InvoiceDatePicker'
                      containerClassName={cx('w-full', {
                        'md:col-span-2': eventType?.includeEndDate
                      })}
                      onSelect={(newDate: Date | undefined) => {
                        setFieldValue('date', newDate)
                      }}
                      value={values.date}
                      fromDate={addDays(
                        new Date(),
                        eventType?.leadMinLeadTime || 0
                      )}
                      errorText={errors.date}
                      required={eventType?.requireTime}
                    />
                    <Select
                      label={eventType?.includeEndDate ? 'Start Time' : 'Time'}
                      name={'time'}
                      iconLeft={<ScheduleIcon accessibility='decorative' />}
                      options={buildTimeSelect()}
                      value={values.time}
                      onChange={(value: any) => setFieldValue('time', value)}
                      containerClassName={'w-full'}
                      errorText={errors.time}
                      required={eventType?.requireTime}
                    />
                    {eventType?.includeEndDate && (
                      <Select
                        label={'End Time'}
                        name={'endTime'}
                        iconLeft={<ScheduleIcon accessibility='decorative' />}
                        options={buildTimeSelect()}
                        value={values.endTime}
                        onChange={(value: any) =>
                          setFieldValue('endTime', value)
                        }
                        containerClassName={'w-full'}
                        errorText={errors.endTime}
                      />
                    )}
                  </div>
                  {eventType && (
                    <BeoForm
                      eventType={eventType}
                      formValues={values.formValues}
                      setFormValues={(formValues: LeadFormValue[]) => {
                        setFieldValue('formValues', formValues)
                      }}
                      errors={touched.formValues && errors.formValues}
                    />
                  )}
                  {eventType?.allowImageUpload && (
                    <>
                      <div className={'text-left'}>
                        <Label>{`${imageFieldName}`}</Label>
                        {eventType?.imageSubtitle && (
                          <div className={'mb-2 type-default text-secondary'}>
                            {eventType?.imageSubtitle}
                          </div>
                        )}
                      </div>
                      <FileInput
                        className={'text-left mb-2'}
                        multiple
                        value={values.attachments}
                        onChange={(attachments) =>
                          setFieldValue('attachments', attachments)
                        }
                        onSubmit={(files) => {
                          return Promise.all(
                            files.map((file) => saveFileToRx(params.rx, file))
                          )
                        }}
                        accept='image/*'
                      />
                    </>
                  )}
                  {(!eventType || !eventType.formFields.length) && (
                    <TextareaField
                      name={'notes'}
                      label='Additional information'
                      placeholder='Anything you want to let us know?'
                      containerClassName={'mb-4'}
                      errorText={errors.notes}
                    />
                  )}
                  <Button
                    disabled={isButtonDisabled(
                      isSubmitting,
                      values,
                      eventType,
                      errors
                    )}
                    type={'submit'}
                    iconLeft={
                      isSubmitting ? (
                        <AutorenewIcon
                          className='animate-spin text-secondary'
                          aria-label={'Auto renew'}
                        />
                      ) : null
                    }
                    className={cx(
                      'w-full mt-2',
                      params.isSitesView === 'true'
                        ? 'self-center max-w-sm'
                        : '',
                      isButtonDisabled(isSubmitting, values, eventType, errors)
                        ? '!bg-gray-25 !text-gray-50'
                        : '!bg-tsw-primary-color !border-tsw-primary-color !text-tsw-primary-contrasting-color hover:!bg-tsw-primary-hover-color hover:!border-tsw-primary-hover-color'
                    )}
                    size={'lg'}
                  >
                    Submit
                  </Button>
                  <p className='w-full text-secondary type-subhead text-center mt-6 mb-4'>
                    By clicking "Submit" you agree to Toast's{' '}
                    <a
                      className='text-link'
                      href={'https://pos.toasttab.com/terms-of-service'}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Guest Terms of Service
                    </a>{' '}
                    and acknowledge that your information will be processed
                    pursuant to Toast's{' '}
                    <a
                      className='text-link'
                      href={'https://pos.toasttab.com/privacy'}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      Privacy Statement
                    </a>
                    . Additional info for California residents available{' '}
                    <a
                      className='text-link'
                      href={'https://pos.toasttab.com/privacy#addendum-a'}
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      here
                    </a>
                    . By providing your phone number, you may also receive
                    automated text messages from Toast and/or this restaurant
                    related to your inquiry. Msg frequency varies. Msg & data
                    rates may apply. Text “STOP” to opt out at any time. Text
                    “HELP” for help.
                  </p>
                </Form>
              )
            }
          }}
        </Formik>
      </div>
    </div>
  )
}
